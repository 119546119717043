import Image from "assets/images/blog/Low-poly-Starting-line_rectangle.png";
import { Link } from "react-router-dom";

const thisIsTheStartingLine = {
  id: "this-is-the-starting-line",
  author: "Alexis Ohanian",
  date: new Date("02/14/2021"),
  title: "This Is the Starting Line",
  image: Image,
  text: (
    <>
      <h3>
        <p className="bold-text">
          2020 was a paradoxical year. On one hand, it was the hardest year, for most Americans, in a very long time. On
          the other, it was one of the best years—for those of us plugged in to tech—because 5 years of adoption
          happened in 5 months. This paradox is a big part of why I had to start Seven Seven Six. Software and the
          internet have won, and they will remain undefeated. Today, every industry is still adapting to that new
          reality. And despite lofty ideals, we have a lot of work to do. Work to make our future all that it can be.
          Work to make that true for <strong>all</strong> of us.
        </p>
      </h3>
      <p>
        I'm incredibly proud of the companies I've built and invested in over the last 15 years. But truthfully, none of
        that was done intentionally. I could have done better. I will do better. And I started taking the steps to make
        sure that's true in June of 2020 when{" "}
        <Link
          to="https://twitter.com/alexisohanian/status/1268943033137053698"
          target="_blank"
          rel="noopener noreferrer"
        >
          I resigned from Reddit
        </Link>{" "}
        and{" "}
        <Link
          to="https://www.axios.com/alexis-ohanian-leaves-initialized-reddit-39657217-608c-49fd-ae27-a38b267c1787.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          left Initialized
        </Link>
        , two companies I co-founded and co-led. Now, I'm ready to build something new.
      </p>
      <p>
        <br />
        <br />
        7️⃣7️⃣6️⃣
      </p>
      <p>
        I named this new firm <strong>Seven Seven Six</strong> for a simple reason: it's a reference to 776 B.C.E., the
        year of the first{" "}
        <Link to="https://www.youtube.com/watch?v=CYoRmfI0LUc" target="_blank" rel="noopener noreferrer">
          Olympic
        </Link>{" "}
        Games. The Olympics are the pinnacle of sports and equity—to be an Olympic Champion is the closest thing in{" "}
        <em>any</em> industry to achieving an objective measure of greatness.
      </p>
      <p>
        The Greeks believed the first Olympics brought the best athletes from all over the world to compete and
        ultimately determine who was the greatest.
      </p>
      <p>The first ever Olympic Champion was a cook from a nearby village who won a 192-meter footrace.</p>
      <p>It's a great story.</p>
      <p>
        At least until you realize the “known world” at that time was only a small fraction of the global
        population—millions of great athletes never got an invite. The Greeks simply didn't know all the other athletes
        even existed. Worse, the Greeks were missing out on all the greatness right there in their own population
        because women couldn't attend (let alone compete).
      </p>
      <p>
        And so in the interest of capturing the same ideal that fueled the first Olympics, to achieve greatness, but
        with the perspective to truly embrace equity, Seven Seven Six was born. We went back to the very first starting
        line to reimagine how to build a firm to attract the best founders and generate the greatest returns—and to do
        it with intention.
      </p>
      <p></p>
      <p>🏅🏅🏅</p>
      <p>
        Celebrating the success I've had as an early investor over a dozen now-billion-dollar-startups feels like I'm
        celebrating the cook from the first Olympics.
      </p>
      <p>
        Surely there were greater founders, greater businesses, and greater returns that I could have invested in—had I
        only been more intentional.
      </p>
      <p>
        Seven Seven Six will be my legacy, and I hope all of you will join me and our team on this journey. We won't be
        perfect, but we will keep striving to be better.
      </p>
      <p>Founders: We want to be the greatest investor you've ever had.</p>
      <p>
        Investing differently also means building our own software because no industry is immune to its impact. My
        favorite work has always been creating products, and I'm delighted we're regularly shipping software to help our
        team, our founders, and our partners to be even more effective and more efficient. We'll be doing our best to
        build in public along the way, too, so please{" "}
        <Link to="https://twitter.com/sevensevensix" target="_blank" rel="noopener noreferrer">
          follow us on Twitter.
        </Link>
      </p>
      <p>
        Finally, thank you to Lissie Garvin and Katelin Holloway for joining me on this starting line. We're announcing
        three new teammates, our 2021 Operators in Residence, this week.
      </p>
    </>
  ),
};

export default thisIsTheStartingLine;
