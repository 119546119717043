import { FC } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { APP_TITLE } from "config/Config";
import useScrollToTop from "components/common/useScrollToTop";
import { Header } from "components";
import Shape from "components/common/Shape";
import { Link } from "react-router-dom";
// import { RoutesEnum } from "utils/Routes";
import OutlinedButton from "components/common/OutlinedButton";
import Tag from "components/common/Tag";

const CareersContainer = styled.section`
  max-width: 1740px;
  position: relative;
  margin: 0 auto;
  padding: 175px 70px 430px 70px;
  background-color: ${({ theme }) => theme.colors.layoutNeutral};
  display: flex;
  flex-direction: column;

  .content-wrapper {
    width: 1100px;
    max-width: 100%;
    position: relative;
    z-index: 1;
  }

  .datetime {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }

  .title {
    width: 1100px;
    max-width: 100%;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-bottom: 15px;
  }

  .text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    p {
      margin-bottom: 30px;
      a {
        text-decoration: underline;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    padding: 115px 25px 75px 25px;
  }
`;

const Careers: FC = () => {
  useScrollToTop();

  const careers = [
    // {
    //   title: "Finance Manager",
    //   location: "Remote (U.S. based)",
    //   published: "March 12th",
    //   to: RoutesEnum.financeManager,
    //   tags: ["100% Remote", "Full-time"],
    //   text: (
    //     <p>
    //       The Finance Manager will play a pivotal role in managing the financial operations of 776 and our investment
    //       funds.
    //       <br />
    //       Our financial operations control how capital flows to and from our various stakeholders, including our LPs,
    //       founders, and business partners. As the Finance Manager, you will meticulously manage all aspects of capital
    //       flows, extract strategic insights, and establish robust internal processes in strict compliance with
    //       regulations to ensure optimal financial efficiency. You'll be reporting into and working closely with the Head
    //       of Finance and Compliance, Michael Cho.
    //     </p>
    //   ),
    // },
  ];

  return (
    <CareersContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} — Careers</title>
      </Helmet>

      <Shape />

      <div className="content-wrapper">
        <Header showShape />

        {careers.length > 0 ? (
          careers?.map((career, index) => (
            <div className="job" key={career.title}>
              <span className="datetime">Published: {career.published}</span>
              <Link to={career.to}>
                <h2 className="title">{career.title}</h2>
              </Link>

              <div className="tags">
                {career.tags?.map((tag) => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
              </div>

              <div className="text">
                {career.text}

                <Link to={career.to}>
                  <OutlinedButton medium>Read more</OutlinedButton>
                </Link>
              </div>
            </div>
          ))
        ) : (
          <div className="check-back-soon">
            <h2 className="title">Check Back Soon!</h2>
            <div className="text">
              <p>
                Thanks for checking in to see if there are any roles at 776 that might be of interest to you! We are
                thrilled to see that you're excited to learn more about working at 776 and exploring if we are a right
                fit for each other. Sadly, we have no open roles to apply for at this time. Please check back again
                soon, and follow us on{" "}
                <a href="https://twitter.com/sevensevensix" target="_blank" rel="noreferrer">
                  X
                </a>{" "}
                and{" "}
                <a href="https://www.linkedin.com/company/69044852/" target="_black">
                  LinkedIn
                </a>{" "}
                to stay up to date on the happenings at the firm!
              </p>
            </div>
          </div>
        )}
      </div>
    </CareersContainer>
  );
};

export default Careers;
