import Image from "assets/images/blog/GeorgoulakisCristina_lowpoly2022_bw-1024x1024.png";
import { Link } from "react-router-dom";

const theNuanceAndTheNeedForFounderOutcomes = {
  id: "the-nuance-and-the-need-for-founder-outcomes",
  author: "Cristina Georgoulakis",
  date: new Date("03/09/2022"),
  title: "The Nuance and the Need for Founder Outcomes",
  image: Image,
  text: (
    <>
      <h1>
        <strong>out·come</strong>
      </h1>
      <p>/'outˌkəm/</p>
      <p>
        <em>noun</em>
      </p>
      <ol>
        <li>the way a thing turns out; a consequence.</li>
      </ol>
      <p> “it is the outcome of the vote that counts”</p>
      <p>
        Every founder's journey is as unique and complex as the founders themselves. While needs vary, one thing is
        consistent: the blood, sweat, and tears are willingly shed (typically alone and behind closed doors) at the
        chance to achieve their desired outcome.
      </p>
      <p>
        As global funding jumps{" "}
        <Link
          to="https://www.cbinsights.com/research/report/venture-trends-2021/"
          target="_blank"
          rel="noopener noreferrer"
        >
          111% YOY
        </Link>
        , competition amongst investors has intensified, giving founders more options and leverage. As a result,
        founders are looking for more from their investors - not just a check. As they should.
      </p>
      <p>
        As a firm made up of founders and operators, Seven Seven Six welcomes this shift and believes it can't come fast
        enough for (<em>all</em>) founders.
      </p>
      <p>
        Many founders liken choosing an investor to getting married - it's that meaningful of a connection. But few
        think about how the qualities of healthy marriages also apply.
      </p>
      <p>
        Healthy marriages are built on mutual respect, trust, equality, honesty, compromise, clear communication,
        support, and those extra little things that keep the relationship growing.
      </p>
      <p>
        At Seven Seven Six we take our role in these two-way relationships seriously. That is why our first internal
        function at the firm is something that is a bit new for the ecosystem: <strong>Founder Outcomes</strong>.
      </p>
      <p>
        We deliberately put our founders and <em>their</em> desired outcomes front and center and design accordingly. It
        is vital that founders feel celebrated and supported. The ongoing success of Seven Seven Six starts and stops
        with our founders.
      </p>
      <h3>
        <strong>Our 3 guiding principles:</strong>
      </h3>

      <p>1️⃣&nbsp; We are designing for our founders' holistic experience, not ours</p>
      <p>2️⃣&nbsp; A founder's journey is not linear, and we're here for all of it</p>
      <p>3️⃣&nbsp; Founders need great outcomes, not great intentions</p>
      <img
        decoding="async"
        loading="lazy"
        src="https://cdn.sevensevensix.com/wp-content/uploads/2022/08/Screen-Shot-2022-08-16-at-5.53.03-PM.png"
        alt=""
      />
      <p></p>
      <h3>
        <strong>The 3 key focus areas for outcomes:</strong>
      </h3>
      <ul>
        <li>Founder Experience</li>
        <li>Founder Community</li>
        <li>Portfolio Outcomes</li>
      </ul>
      <h2>
        <strong>What are we building?</strong>
      </h2>
      <p>
        Merging my past life as an operator and present life as an investor, I've taken a human-centric design approach
        to architecting our new Founder Outcomes function.
      </p>
      <p>
        Listening, observing, accompanying, and co-creating. It's my privilege to make sure our founders feel seen and
        heard as we build alongside them.
      </p>
      <h3>
        <strong>📦 What we have shipped so far.</strong>
      </h3>
      <ul>
        <li>
          <strong>Our 1st Founder Voice:</strong>
          <ul>
            <li>
              A report comprised of qualitative and quantitative feedback from +70% of our portfolio founders, focusing
              on their experiences and expectations from our partnership.
            </li>
          </ul>
        </li>
        <li>
          <strong>Founder Outcomes Journey</strong>:
          <ul>
            <li>
              A v1 journey built to meet our founders where they are and with what they need to be successful as whole
              humans.
            </li>
            <li>
              Informed by the five functional and emotional “
              <Link
                to="https://www.christenseninstitute.org/jobs-to-be-done/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Jobs to be Done
              </Link>
              ,” shared and developed with our founders.
            </li>
          </ul>
        </li>
        <li>
          <strong>Mini Board Meeting (</strong>
          <Link
            to="https://www.notion.so/776/7-7-6-Quarterly-Mini-Board-Meetings-Template-57362436fa0f4da3b32a8dbbb919b92d"
            data-type="URL"
            data-id="https://www.notion.so/776/7-7-6-Quarterly-Mini-Board-Meetings-Template-57362436fa0f4da3b32a8dbbb919b92d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>MBMs</strong>
          </Link>
          <strong>):</strong>
          <ul>
            <li>
              The top request from our founders to get the whole 7️⃣7️⃣6️⃣ squad together to deep-dive on strategic and
              tactical topics.
            </li>
            <li>
              This is a safe place to practice, learn, and grow the skill of presenting to - and leveraging input from -
              a Board on a quarterly basis.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        As with any great system design, it will perpetually be a work-in-progress. We plan to test, launch, and iterate
        on all of our initiatives. Our work is never done, and we are always at the starting line.
      </p>
      <p>
        We made a conscious decision to build a team with Community and Experience Design at its core, knowing how
        critical it is to make this investment early. With this focus, the Founder Outcomes team can operate both at
        scale and intimately with our founder community, setting each and every one of them up for long-term success in
        a way that's unique to them - not designed at a high-level for people like them.
      </p>
      <h2>
        <strong>Why me?</strong>
      </h2>
      <p>
        As a former founder, operator, and now investor, I bring all three lenses to the table to craft this function a
        bit differently.
      </p>
      <p>
        My operational background is in building and operationalizing customer-centric teams in hyper-growth SaaS
        startups, with a strong focus on retaining and growing customers and teammates through value-driven initiatives.
      </p>
      <p>
        <strong>But, more importantly…</strong>
      </p>
      <p>
        Facilitating growth in people and organizations is my
        <Link to="https://positivepsychology.com/ikigai/" target="_blank" rel="noopener noreferrer">
          {" "}
          <em>Ikigai</em>
        </Link>{" "}
        - reason for being.
      </p>
      <p>
        My north star is keeping the triple bottom line in balance: a company's mission, people, and business outcomes.
        I do that with a whole lot of empathy, some tough love, and an extra helping of humble pie from my own lived
        experiences.
      </p>
      <p>
        Founders, I look forward to building alongside you! And that starts with hearing from more of you, so please do
        get in touch with ideas, thoughts, and feedback. We are just at the starting line.
      </p>
    </>
  ),
};

export default theNuanceAndTheNeedForFounderOutcomes;
