import { FC, useMemo, CSSProperties, useCallback } from "react";
import styled from "styled-components";
import useScrollTop from "./useScrollTop";
import useScreenSize from "./useScreenSize";
import { ReactComponent as Img1 } from "assets/images/homepage/1.svg";
import { ReactComponent as Img2 } from "assets/images/homepage/2.svg";
import { ReactComponent as Img3 } from "assets/images/homepage/3.svg";
import { ReactComponent as Img4 } from "assets/images/homepage/4.svg";
import { ReactComponent as Img5 } from "assets/images/homepage/5.svg";
import { ReactComponent as Img6 } from "assets/images/homepage/6.svg";
import { ReactComponent as Img7 } from "assets/images/homepage/7.svg";
import { ReactComponent as Img8 } from "assets/images/homepage/8.svg";
import { ReactComponent as Img9 } from "assets/images/homepage/9.svg";
import { ReactComponent as Img10 } from "assets/images/homepage/10.svg";
import { ReactComponent as Img11 } from "assets/images/homepage/11.svg";
import { ReactComponent as Img12 } from "assets/images/homepage/12.svg";
import { ReactComponent as Img13 } from "assets/images/homepage/13.svg";
import { ReactComponent as Img14 } from "assets/images/homepage/14.svg";
import { ReactComponent as Img15 } from "assets/images/homepage/15.svg";
import { ReactComponent as Img16 } from "assets/images/homepage/16.svg";
import { ReactComponent as Img17 } from "assets/images/homepage/17.svg";
import { ReactComponent as Img18 } from "assets/images/homepage/18.svg";
import { ReactComponent as Img19 } from "assets/images/homepage/19.svg";
import { ReactComponent as Img20 } from "assets/images/homepage/20.svg";
import { ReactComponent as Img21 } from "assets/images/homepage/21.svg";
import { ReactComponent as Img22 } from "assets/images/homepage/22.svg";
import { ReactComponent as Img23 } from "assets/images/homepage/23.svg";
import { ReactComponent as Img24 } from "assets/images/homepage/24.svg";
import { ReactComponent as Img25 } from "assets/images/homepage/25.svg";
import { ReactComponent as Img26 } from "assets/images/homepage/26.svg";
import { ReactComponent as Img27 } from "assets/images/homepage/27.svg";
import { ReactComponent as Img28 } from "assets/images/homepage/28.svg";
import { ReactComponent as Img29 } from "assets/images/homepage/29.svg";
import { ReactComponent as Img30 } from "assets/images/homepage/30.svg";
import { ReactComponent as Img31 } from "assets/images/homepage/31.svg";
import { ReactComponent as Img32 } from "assets/images/homepage/32.svg";
import { ReactComponent as Img33 } from "assets/images/homepage/33.svg";
import { ReactComponent as Img34 } from "assets/images/homepage/34.svg";
import { ReactComponent as Img35 } from "assets/images/homepage/35.svg";
import { ReactComponent as Img36 } from "assets/images/homepage/36.svg";
import { ReactComponent as Img37 } from "assets/images/homepage/37.svg";
import { ReactComponent as Img38 } from "assets/images/homepage/38.svg";
import { ReactComponent as Img39 } from "assets/images/homepage/39.svg";
import { ReactComponent as Img40 } from "assets/images/homepage/40.svg";

const ScrollImagesDiv = styled.div``;

interface ScrollImagesProps {
  height?: number;
  stylings?: {
    [key: string]: CSSProperties;
  };
}

const frames: React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string;
  }
>[] = [
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img16,
  Img17,
  Img18,
  Img19,
  Img20,
  Img21,
  Img22,
  Img23,
  Img24,
  Img25,
  Img26,
  Img27,
  Img28,
  Img29,
  Img30,
  Img31,
  Img32,
  Img33,
  Img34,
  Img35,
  Img36,
  Img37,
  Img38,
  Img39,
  Img40,
];

const ScrollImages: FC<ScrollImagesProps> = ({ height }) => {
  const scrollTop = useScrollTop();
  const { isMobile, height: screenHeight, width: screenWidth } = useScreenSize();

  const isMediumDesktop = useMemo(() => screenWidth <= 1280, [screenWidth]);

  const getStyle = useCallback(
    (index) => {
      const width = screenWidth > 1740 ? 1740 : screenWidth;
      const lastIndex = !isMediumDesktop ? 25 : frames.length - 6;
      if (index > lastIndex) return {};

      const multiplayer = 1 - index / lastIndex;

      let startY = screenHeight / 2 + 100;
      if (isMediumDesktop) startY = screenHeight / 2 + 350;
      if (isMobile) startY = screenHeight + 580;

      let startX = width / 2 - 350;
      if (isMediumDesktop) startX = width - 450;
      if (isMobile) startX = width;

      startY = startY * multiplayer * -1;

      const x = (startX / lastIndex) * (lastIndex - index);
      const y = (startY / lastIndex) * (lastIndex - index);

      return { transform: `translate(${x}px, ${y}px)` };
    },
    [screenHeight, isMobile, screenWidth, isMediumDesktop]
  );

  const currentIndex = useMemo(() => {
    const changeDistance = height / frames.length;
    const idx = Math.floor(scrollTop / changeDistance);
    return !idx || idx < 0 ? 0 : idx;
  }, [scrollTop, height]);

  const currentImage = useMemo(() => {
    const SvgFrame = frames[currentIndex];
    return !SvgFrame ? null : <SvgFrame style={getStyle(currentIndex)} />;
  }, [currentIndex, getStyle]);

  const scaleStyle = useMemo(() => {
    const startIndex = 0;
    const start = isMobile ? 0.5 : isMediumDesktop ? 0.8 : 1;
    const end = isMobile ? 0.22 : isMediumDesktop ? 0.435 : 0.337;
    const scallable = start - ((currentIndex - startIndex) * (start - end)) / (frames.length - startIndex);
    return `scale(${scallable})`;
  }, [isMobile, currentIndex, isMediumDesktop]);

  return (
    <ScrollImagesDiv style={{ transform: scaleStyle, transformOrigin: isMediumDesktop ? "bottom left" : "center" }}>
      {currentImage}
    </ScrollImagesDiv>
  );
};

export default ScrollImages;
