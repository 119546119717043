import clsx from "clsx";
import { FC } from "react";
import styled from "styled-components";

const MainTag = styled.span`
  display: block;
  height: 24px;
  line-height: 24px;
  border: 1px solid ${({ theme }) => theme.colors.layoutDark};
  color: ${({ theme }) => theme.colors.layoutDark};
  border-radius: 999px;
  box-sizing: content-box;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 0 10px;
  cursor: auto;
`;

interface TagProps {
  children: React.ReactNode;
  className?: string;
}

const Tag: FC<TagProps> = ({ children, className }) => {
  return (
    <MainTag className={clsx(className, "tag")}>
      {children}
    </MainTag>
  );
};

export default Tag;
