import { ReactNode } from "react";
import { calculatePercentage, getWauMauStats } from "utils/utils";

export interface StatsInterface {
  amplifiedPosts?: number;
  contentCreated?: number;
  founderMeetings?: number;
  impressions?: number;
  interactions?: number;
  introductions?: number;
  founderChatMessages?: number;
  messageReplyMedian?: number | string;
  networkSearches?: number;
  salesPitchesAndMeetings?: number;
  workshops?: number;
  tasksAndResearches?: number;
  totalContacts?: number;
  favors?: number;
  events?: number;
  supportHiring?: number;
  totalUsers?: number;
  dailyUsers?: number;
  weeklyUsers?: number;
  monthlyUsers?: number;
  userStats?: UserStats;
}

export interface UserStats {
  founders: UserStatsDetails;
  lps: UserStatsDetails;
  sevenSevenSix: UserStatsDetails;
}

export interface UserStatsDetails {
  dau: number;
  mau: number;
  wau: number;
  total: number;
}

export enum ColSpanEnum {
  XL = "xl",
  L = "l",
  MEDIUM_DESKTOP = "md",
  BELLOW_TABLET_LANDSCAPE = "btl",
  BELLOW_TABLE_PORTRAIT = "btp",
}

export interface ColSpan {
  [ColSpanEnum.XL]?: number;
  [ColSpanEnum.MEDIUM_DESKTOP]?: number;
  [ColSpanEnum.BELLOW_TABLET_LANDSCAPE]?: number;
  [ColSpanEnum.BELLOW_TABLE_PORTRAIT]?: number;
}

export interface StatCardInterface {
  className?: string;
  hide?: boolean;
  value: number | string | ReactNode;
  text: string | ReactNode;
  additionalText?: string | ReactNode;
  isString?: boolean;
  light?: boolean;
  backgroundColor?: string;
  colSpan?: ColSpan;
  colSpanPopup?: ColSpan;
}

export const stats365 = (stats: StatsInterface, isAlexis?: boolean): StatCardInterface[] => [
  {
    value: stats?.founderMeetings,
    text: "Meetings with Founders & Their Teams",
    colSpan: { md: 15, btl: 20, btp: 30 },
    colSpanPopup: { md: 20, btl: 30 },
  },
  {
    value: stats?.workshops,
    text: "Workshops for Founders",
    colSpan: { md: 15, btl: 20, btp: 30 },
    colSpanPopup: { md: 20, btl: 30 },
  },
  {
    value: stats?.contentCreated,
    text: "Original Content created for Founders",
    colSpan: { md: 15, btl: 20, btp: 30 },
    colSpanPopup: { md: 20, btl: 30 },
  },
  {
    value: stats?.salesPitchesAndMeetings,
    text: "Sales & Fundraising Meetings for Founders",
    colSpan: { md: 15, btl: 20, btp: 30 },
    colSpanPopup: { md: 20, btl: 30 },
  },
  {
    value: stats?.introductions,
    text: "Intros Made for Founders",
    colSpan: { md: 15, btl: 20, btp: 30 },
    colSpanPopup: { md: 20, btl: 30 },
  },
  {
    value: stats?.favors,
    text: "Favors Called in For Founders",
    colSpan: { md: 15, btl: 20, btp: 30 },
    colSpanPopup: { md: 20, btl: 30 },
  },
  {
    value: stats?.events,
    text: "Events Organized & Attended for Founders",
    colSpan: { md: 15, btl: 20, btp: 30 },
    colSpanPopup: { md: 20, btl: 30 },
  },
  {
    value: stats?.supportHiring,
    text: "New Hires Sourced & Closed for Founders",
    colSpan: { md: 15, btl: 20, btp: 30 },
    colSpanPopup: { md: 20, btl: 30 },
  },
  {
    value: stats?.tasksAndResearches,
    text: "Tasks & Research Done for Founders",
    colSpan: { md: 15, btl: 20, btp: 30 },
    colSpanPopup: { md: 20, btl: 30 },
  },
];

export const stats776Founders = (stats: StatsInterface, isAlexis?: boolean): StatCardInterface[] =>
  [
    {
      hide: isAlexis,
      value: stats?.networkSearches,
      text: `Cerebro Network Searches by Founders (Of ${new Intl.NumberFormat().format(
        stats?.totalContacts || 0
      )} contacts)`,
      colSpan: { md: 15, btl: 20, btp: 30 },
    },
    {
      hide: isAlexis,
      value: Number(stats?.founderChatMessages || 0),
      text: `Cerebro Chat Exchanges With Founders`,
      colSpan: { md: 15, btl: 20, btp: 30 },
    },
    {
      hide: isAlexis,
      value: stats?.messageReplyMedian,
      isString: true,
      text: `Our Median Response Time to Founders`,
      colSpan: { md: 15, btl: 20, btp: 30 },
    },
    {
      value: stats?.amplifiedPosts,
      text: "Content Amplified for Founders",
      colSpan: { xl: 15, md: 30, btl: 30, btp: 60 },
      colSpanPopup: { xl: 30, md: 20, btl: 30 },
    },
    {
      value: stats?.interactions,
      text: "Total Interactions With Amplified Content",
      colSpan: { xl: 15, md: 30, btl: 30, btp: 60 },
      colSpanPopup: { xl: 30, md: 20, btl: 30 },
    },
    {
      value: stats?.impressions,
      text: "Total Impressions Generated By Amplified Content",
      colSpan: { xl: 15, md: 30, btl: 30, btp: 60 },
      colSpanPopup: { xl: 30, md: 20, btl: 30 },
    },
    {
      hide: isAlexis,
      value: stats?.totalUsers,
      text: "Cerebro Total Users",
      colSpan: { xl: 15, md: 30, btl: 30, btp: 60 },
    },
  ].filter((i) => !i.hide);

export const statsUsers = (stats: StatsInterface, teamCount?: number): StatCardInterface[] => [
  //  Team on Cerebro
  {
    value: teamCount ?? (stats?.userStats?.sevenSevenSix?.total || 0),
    text: "Total 776 Team on Cerebro",
    colSpan: { xl: 12, md: 12, btl: 12, btp: 30 },
  },
  {
    value: getWauMauStats(stats?.userStats?.sevenSevenSix?.wau, stats?.userStats?.sevenSevenSix?.mau),
    text: "Team WAU / MAU",
    colSpan: { xl: 12, md: 12, btl: 12, btp: 30 },
    additionalText: "%",
  },
  {
    value: stats?.userStats?.sevenSevenSix?.mau,
    text: "Team MAU",
    colSpan: { xl: 12, md: 12, btl: 12, btp: 30 },
    additionalText: calculatePercentage(stats?.userStats?.sevenSevenSix?.total, stats?.userStats?.sevenSevenSix?.mau),
  },
  {
    value: stats?.userStats?.sevenSevenSix?.wau,
    text: "Team WAU",
    colSpan: { xl: 12, md: 12, btl: 12, btp: 30 },
    additionalText: calculatePercentage(stats?.userStats?.sevenSevenSix?.total, stats?.userStats?.sevenSevenSix?.wau),
  },
  {
    value: stats?.userStats?.sevenSevenSix?.dau,
    text: "Team DAU",
    colSpan: { xl: 12, md: 12, btl: 12, btp: 30 },
    additionalText: calculatePercentage(stats?.userStats?.sevenSevenSix?.total, stats?.userStats?.sevenSevenSix?.dau),
  },
  //founders
  {
    value: stats?.userStats?.founders?.total || 0,
    text: "Total Founders on Cerebro",
    colSpan: { xl: 12, md: 12, btl: 12, btp: 30 },
  },
  {
    value: getWauMauStats(stats?.userStats?.founders?.wau, stats?.userStats?.founders?.mau),
    text: "Founder WAU / MAU",
    colSpan: { xl: 12, md: 12, btl: 12, btp: 30 },
    additionalText: "%",
  },
  {
    value: stats?.userStats?.founders?.mau,
    text: "Founder MAU",
    colSpan: { xl: 12, md: 12, btl: 12, btp: 30 },
    additionalText: calculatePercentage(stats?.userStats?.founders?.total, stats?.userStats?.founders?.mau),
  },
  {
    value: stats?.userStats?.founders?.wau,
    text: "Founder WAU",
    colSpan: { xl: 12, md: 12, btl: 12, btp: 30 },
    additionalText: calculatePercentage(stats?.userStats?.founders?.total, stats?.userStats?.founders?.wau),
  },
  {
    value: stats?.userStats?.founders?.dau,
    text: "Founder DAU",
    colSpan: { xl: 12, md: 12, btl: 12, btp: 30 },
    additionalText: calculatePercentage(stats?.userStats?.founders?.total, stats?.userStats?.founders?.dau),
  },
];
