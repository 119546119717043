import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback } from "react";
import { TeamCard } from "team";
import useScreenSize from "components/common/useScreenSize";
import FadeUp from "components/common/FadeUp";
import { useNavigate } from "react-router-dom";
import { RoutesEnum } from "utils/Routes";
import clsx from "clsx";

const MemberCard = styled.div`
  min-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 63px 37px 43px 37px;
  transition: all 0.3s ease-in-out;

  &.borderRight {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
  }

  &.borderBottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  p {
    margin: 0px;
  }

  .member-picture {
    margin-bottom: 40px;
    width: 240px;
    height: 282px;
    object-fit: contain;
  }

  .member-name {
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: calc(19rem / 16);
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 5px;
  }

  .member-position {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: calc(15rem / 16);
    font-weight: 400;
    height: 36px;
    line-height: 1.5;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    cursor: auto;
    &:hover {
      cursor: auto;
    }
  }
`;

interface TeamMemberCardProps {
  member: TeamCard;
  animate: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
}

const TeamMemberCard = ({ member, animate, borderRight, borderBottom }: TeamMemberCardProps) => {
  const { width } = useScreenSize();
  const navigate = useNavigate();

  const openPreview = useCallback(() => {
    navigate(RoutesEnum.teamView(member.id));
  }, [navigate, member]);

  return (
    <AnimatePresence mode="popLayout">
      <FadeUp show={animate}>
        <MemberCard
          onClick={openPreview}
          className={clsx(borderBottom && "borderBottom", borderRight && "borderRight")}
        >
          <>
            <motion.img layoutId={width >= 1024 && member.id} src={member.image} alt="" className="member-picture" />
            <p className="member-name">{member.name}</p>
            <p className="member-position">{member.position}</p>
          </>
        </MemberCard>
      </FadeUp>
    </AnimatePresence>
  );
};

export default TeamMemberCard;
