import teamMembersData from "./teamMembers";

export interface TeamCard {
  id: string;
  name: string;
  position: string;
  image?: string;
  text: string;
  socials: {
    twitter?: string;
    instagram?: string;
    linkedin?: string;
    tiktok?: string;
    angellist?: string;
    web?: string;
  };
}

export default teamMembersData as TeamCard[];
