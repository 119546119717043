import Image from "assets/images/blog/Low-poly-portraits-Cristina-Travis-Hiram-e1671567679784-980x551.png";
import { Link } from "react-router-dom";

const announcingOur2021OperatorsInResidence = {
  id: "announcing-our-2021-operators-in-residence",
  author: "Katelin Holloway",
  date: new Date("02/19/2021"),
  title: "Announcing Our 2021 Operators in Residence",
  image: Image,
  text: (
    <>
      <h3>
        <p className="bold-text">
          In mid-November 2020, we shared our firm's very first{" "}
          <Link
            to="https://twitter.com/sevensevensix/status/1329107127282118656"
            target="_blank"
            rel="noopener noreferrer"
          >
            job posting
          </Link>
          . It was an invitation to join the Seven Seven Six{" "}
          <Link to="https://www.sevensevensix.com/careers" target="_blank" rel="noopener noreferrer">
            Operator in Residence Program
          </Link>
          . Not knowing what to expect, I anxiously told Alexis and Lissie that we would be successful if we got 100
          applicants. Hosting an open application process is rare in venture, with most firms hiring talent from their
          limited personal networks or earning social capital by hiring friends. Our application process was different
          and we knew it. We didn't ask for resumes, LinkedIn links, or MBA credentials. We asked for builders. And we
          asked for their perspective.
        </p>
      </h3>

      <p>&nbsp;</p>
      <h3>Their Work in Action:</h3>
      <p>
        The team started with us in late January and have spent their first few weeks getting to know each of our
        founders, sitting in on pitch meetings, and are beginning to dive into their support work with the Seven Seven
        Six portfolio companies.
      </p>
      <p>
        An example from last week was when a founder shared in an Office Hours that they were struggling with hiring
        their first designer. Cristina quickly jumped on the line with them and walked through their interview process,
        their candidate pipeline, job description, compensation, and candidate profile. She identified their points of
        disconnect and made adjustments with them live in a working session. She followed up the next day with a{" "}
        <Link
          to="https://www.notion.so/776/Hiring-your-1st-Product-Designer-d7c0c474ea9e47c994abcaae9ed7720c"
          target="_blank"
          rel="noopener noreferrer"
        >
          “How to Hire Your First Designer”
        </Link>{" "}
        guide that can be used by any of our founders (and any of you!). Immediate and actionable feedback — in a
        productized and easy to translate package — is exactly what our founders ask for.
      </p>
      <p>
        In addition to the hands-on work with our portfolio, the Operators are getting a front-row seat to every part of
        building this new firm, including reviewing deals, launching a new fund, and learning about the mechanics of
        venture capital. We'll host formal AMAs and fireside chats with seasoned investors, putting equal emphasis on
        the learning track as much as the building track.&nbsp;
      </p>
      <p>&nbsp;</p>

      <h3>The Future:</h3>
      <p>
        There is so much we're collectively looking forward to in 2021. To start, we recognize the incredible talent in
        our applicant pool and are actively building out a community to keep these people in our orbit. The goal is to
        bring these operators together, enable meaningful connections, share resources, and, ultimately, do what we can
        to support everyone on their individual journeys to venture capital. More on our Operator Community soon!
      </p>
      <p>
        Most of all, we're looking forward to learning together. In just a few short weeks, Cristina, Hiram, and Travis
        have already brought so much to the Seven Seven Six team — experience, enthusiasm, and perspective. We feel that
        if we continue on this path, we can easily scale this program every year, creating deeper levels of support and
        inviting many more unique voices into our industry. For now, we're going to enjoy the work in front of us and do
        our best to make our time together meaningful and rewarding.
      </p>
    </>
  ),
};

export default announcingOur2021OperatorsInResidence;
