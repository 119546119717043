import PlayImage from "assets/images/play.png";
import WatermarkImage from "assets/images/watermark-logo.png";
import Button from "components/common/Button";
import useMusings from "components/useMusings";
import { FC, createRef, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { RoutesEnum } from "utils/Routes";
import Hls from "hls.js";

const MusingsDiv = styled.div`
  margin-bottom: 150px;
  header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1740px;
    width: 100%;
    margin: 0 auto;
    padding: 47px 64px 47px 76px;
  }

  .hype-preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 85px;
    h2 {
      max-width: 400px;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
    }

    .video-preview {
      margin-top: 20px;
      margin-bottom: 50px;
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.7) 0px 18px 50px -10px;
      video {
        display: block;
      }
      .play-button {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .watermark {
        position: absolute;
        bottom: 25px;
        max-width: 80%;
        right: 25px;
      }
    }

    .share {
      display: flex;
      gap: 24px;
    }
  }

  video,
  .preview-image {
    max-width: 600px;
    max-height: 600px;
  }
`;

const MusingsPage: FC = () => {
  const { item, loading } = useMusings();
  const [videoPaused, setVideoPaused] = useState(true);
  const [initialized, setInitialized] = useState(false);

  const videoRef = createRef<HTMLVideoElement>();

  const togglePlay = useCallback(() => {
    const video = videoRef.current;
    setVideoPaused(!video.paused);
    video.paused ? video.play() : video.pause();
  }, [videoRef]);

  useEffect(() => {
    const videoSrc = item?.linkToAsset;
    if (videoSrc && videoRef.current && !initialized) {
      setInitialized(true);
      if (videoRef.current?.canPlayType("application/vnd.apple.mpegurl")) {
        videoRef.current.src = videoSrc;
      } else if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(videoSrc);
        hls.attachMedia(videoRef.current);
      }
    }
  }, [videoRef, initialized, item?.linkToAsset]);

  if (loading || !item) return null;

  const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
  const safariProps = isSafari ? { controls: true, playsInline: true } : {};

  return (
    <MusingsDiv>
      <header>
        <Link to={RoutesEnum.home}>
          <img src="/icons/776-logo.svg" alt="776" />
        </Link>

        <a href="https://776.org" target="_blank" rel="noreferrer">
          <img src="/icons/heart.svg" alt="" width={26} height={26} />
        </a>
      </header>

      <div className="hype-preview">
        <h2>{item?.recorderName} recorded this for you with Cerebro!</h2>

        <div className="video-preview" onClick={togglePlay}>
          <video ref={videoRef} {...safariProps} />
          {videoPaused && <img className="play-button" alt="play button" src={PlayImage} />}
          {(item?.watermark ?? true) && <img className="watermark" src={WatermarkImage} alt="watermark" />}
        </div>

        <div className="share">
          <a href={`https://twitter.com/intent/tweet?url=${item?.linkToPage}`} target="_blank" rel="noreferrer">
            <Button dark>Share on X</Button>
          </a>
          <a href={item?.linkToDownload} target="_blank" rel="noreferrer">
            <Button dark>Download</Button>
          </a>
        </div>
      </div>
    </MusingsDiv>
  );
};

export default MusingsPage;
