import AlexisOhanian from "assets/images/team/Alexis-Ohanian.png";
import KatelinHolloway from "assets/images/team/Katelin-Holloway.png";
import CristinaGeorgoulakis from "assets/images/team/Cristina-Georgoulakis.png";
import ChrisVanzetta from "assets/images/team/Chris-Vanzetta.png";
import MichaelCho from "assets/images/team/Michael-Cho.png";
import HarryChiang from "assets/images/team/Harry-Chiang.png";
import MarlonPrimous from "assets/images/team/Marlon-Primous.png";
import MaggiComeaux from "assets/images/team/Maggi-Comeaux.png";
import AshrafShemirani from "assets/images/team/Ash-Shemirani.png";
import SarahBoles from "assets/images/team/Sarah-Boles.png";
import NefitieriMoncur from "assets/images/team/Nefitieri-Moncur.png";
import KenyNguyen from "assets/images/team/Keny-Nguyen.png";
import StephanieSica from "assets/images/team/Stephanie-Sica.png";

const data = [
  {
    id: "1",
    name: "Alexis Ohanian",
    position: "General Partner, Founder",
    image: AlexisOhanian,
    text: 'In 2020, Alexis founded Seven Seven Six, an early stage venture capital firm with $970M AUM. At heart, the firm is a technology company that deploys venture capital. Two years later, he launched his family foundation <a href="https://776.org" target="_blank">776.org</a> to combat inequity worldwide through entrepreneurship and announced a $20M commitment to climate action through his new 776 Climate Fellowship. He also wrote a national bestselling book, <i>Without Their Permission</i>, for which he embarked on a 5-month bus tour speaking at 82 universities across America about innovation. He was a partner at Y Combinator and a co-founder of Initialized Capital. Prior to that, in 2005, he co-founded Reddit. He departed in 2010, only to return as executive chairman in 2014 to help lead the turnaround of what is now one of the largest websites in the U.S. and currently valued at more than $10B.',
    socials: {
      twitter: "https://twitter.com/alexisohanian",
      instagram: "https://instagram.com/alexisohanian",
      linkedin: "https://www.linkedin.com/in/alexisohanian/",
      tiktok: "https://tiktok.com/@alexisohanian",
      web: "http://alexisohanian.com/",
    },
  },
  {
    id: "2",
    name: "Katelin Holloway",
    position: "Founding Partner",
    image: KatelinHolloway,
    text: "As Founding Partner, Katelin actively invests across various sectors, oversees all firm and portfolio operations, and leads investor relations. Katelin has a unique perspective on the intersection of people and technology. She brings over 15 years of executive and operating experience to the world of venture, having spent significant time at some of this generation’s most influential technology companies like Pixar Animation Studios, Klout, and Reddit. Leveraging her expertise in HR, operations, and culture building, Katelin is focused on driving outsized returns with care. As an investor, she is passionate about funding and coaching the people-first companies that will shape the future of work, life, sustainability, and the delicate balance that enables us to thrive.",
    socials: {
      twitter: "https://twitter.com/katelin_cruse",
      instagram: "https://instagram.com/katelin_cruse",
      linkedin: "https://www.linkedin.com/in/katelinholloway/",
      web: "https://www.katelinholloway.com/",
    },
  },
  {
    id: "3",
    name: "Cristina Apple Georgoulakis",
    position: "Partner",
    image: CristinaGeorgoulakis,
    text: "Systems-thinking CX leader turned venture capitalist, Cristina values driving growth in people and organizations. She previously co-founded an e-learning SaaS platform in Barcelona. After seven years of building her career and community abroad, she moved to San Francisco to open Typeform’s first U.S. office. She’s held a number of leadership roles at hyper-growth SaaS startups where she built and operationalized teams from scratch. As an investor, Cristina is drawn to startups where technology meets human connection, emphasizing the approach to maximizing returns while keeping a keen eye on the triple-bottom line: profits, people, and process.",
    socials: {
      twitter: "https://twitter.com/cristina_laki",
      instagram: "https://www.instagram.com/worndustysoles/",
      linkedin: "https://www.linkedin.com/in/claki/",
    },
  },
  {
    id: "4",
    name: "Chris Vanzetta",
    position: "Partner",
    image: ChrisVanzetta,
    text: "Chris brings over a decade of experience as an HR leader working at hyper-growth technology companies and Fortune 50 organizations to Seven Seven Six. In addition to his budding investor career, he also leads firm operations, manages our incubation program, and supports our portfolio companies on recruiting, HR, culture, and workforce strategies. Prior to joining the firm, Chris was the Global Head of HR at Reddit. He’s interested in funding companies that focus on wellness and well-being, both inside and out of the workplace.",
    socials: {
      twitter: "https://twitter.com/vanzetta_chris",
      linkedin: "https://www.linkedin.com/in/christopher-vanzetta-99209712b/",
    },
  },
  {
    id: "5",
    name: "Michael Cho",
    position: "Head of Finance & Compliance",
    image: MichaelCho,
    text: "Michael manages the financial strategy and regulatory compliance efforts, overseeing budgeting, financial reporting, and risk management. Before joining Seven Seven Six, Michael was a research analyst at Osmium Partners, a long/short hedge fund specializing in small-cap equities. He brings over a decade of experience working in investment management.",
    socials: {
      linkedin: "https://www.linkedin.com/in/michaelch0/",
    },
  },
  {
    id: "6",
    name: "Keny Nguyen",
    position: "Finance Manager",
    image: KenyNguyen,
    text: "As part of the Finance team, Keny plays a key role in managing the financial strategy and regulatory compliance efforts, overseeing budgeting, financial reporting, and risk management. Prior to joining Seven Seven Six, he started his career at PwC providing assurance services to various tech, life science, and venture capital clients in the Bay Area, and was most recently a Finance Manager at Venrock, a venture firm based in Palo Alto.",
  },
  {
    id: "7",
    name: "Harry Chiang",
    position: "Outcomes Manager",
    image: HarryChiang,
    text: "Harry manages our Portfolio Outcomes team, working on everything from due diligence, founder support, incubations, fundraising, building internal functions, product, and more. He has worked closely with the partners on every new investment the firm has made since joining in 2021. He was in investment banking covering consumer before joining the Seven Seven Six team.",
    socials: {
      twitter: "https://twitter.com/harryxchiang",
      linkedin: "https://www.linkedin.com/in/harryxchiang/",
    },
  },
  {
    id: "8",
    name: "Marlon Primous",
    position: "Outcomes Associate",
    image: MarlonPrimous,
    text: "As an associate, Marlon does due diligence on prospective investments while supporting our portfolio company’s founders with their financial infrastructure. He previously was a senior manager of finance at Buzzer Technologies and an investment banking analyst at Morgan Stanley. His acute understanding of early-stage companies is underscored by his status as a guest lecturer at Kellogg School of Business and a former VC scout for Concrete Rose Capital.",
    socials: {
      twitter: "https://twitter.com/OptimusPrimous",
      linkedin: "https://www.linkedin.com/in/marlon-primous-84633311b/",
    },
  },
  {
    id: "9",
    name: "Stephanie Sica",
    position: "Head of Product",
    image: StephanieSica,
    text: 'As Head of Product, Steph leads the development and evolution of the firm’s software platform, Cerebro, that powers the investing, portfolio management, marketing processes and general firm operations for our founders, team, and investors. Prior to joining the team, Steph held product roles at companies in various stages of growth, including Rent the Runway, Mercato, <a href="https://jet.com" target="_blank">Jet.com</a> and Walmart. She brings over a decade of experience in product management to Seven Seven Six.',
    socials: {
      linkedin: "https://www.linkedin.com/in/stephaniesica/",
    },
  },
  {
    id: "10",
    name: "Maggi Comeaux",
    position: "Executive Assistant to Alexis Ohanian",
    image: MaggiComeaux,
    text: "Maggi brings her passion for service, organizational excellence, and project management to her role as Executive Assistant to Alexis Ohanian. In addition to providing the General Partner and Founder with holistic administrative support, Maggi partners with the greater Seven Seven Six team to advance all Firm initiatives. Maggi has previously held leader assistant roles at Frazier Healthcare Partners, Vista Equity Partners, Axcess Worldwide, and VMware.",
    socials: {
      twitter: "https://twitter.com/maggicomeaux",
      linkedin: "https://www.linkedin.com/in/maggicomeaux/",
    },
  },
  {
    id: "11",
    name: "Nefitieri Moncur",
    position: "Executive Assistant to Alexis Ohanian",
    image: NefitieriMoncur,
    text: "Nefitieri brings 15 years of experience building and scaling organizational processes to her role as Executive Assistant to Alexis Ohanian. She fosters cross-functional collaboration, infusing every interaction with a hospitality-first ethos. Prior to joining Seven Seven Six, she lent her expertise as Chief of Staff and administrative lead to esteemed firms including Grayscale Investments, Chainalysis, Oaktree Capital and Finsbury.",
    socials: {
      linkedin: "https://www.linkedin.com/in/nefitieri/",
    },
  },
  {
    id: "12",
    name: "Sarah Boles",
    position: "Executive Assistant to Katelin Holloway",
    image: SarahBoles,
    text: "In addition to supporting Founding Partner Katelin Holloway, Sarah collaborates with our Events team to create memorable experiences for our founders and portfolio companies. She brings over 15 years of administrative experience across a diversity of industries from global logistics to health and wellness. Before joining Seven Seven Six, Sarah supported multiple executives at Flexport.",
    socials: {
      linkedin: "https://www.linkedin.com/in/sarah-boles-94a4366/",
    },
  },
  {
    id: "13",
    name: "Ashraf Shemirani",
    position: "Executive Assistant to Cristina Georgoulakis and Chris Vanzetta",
    image: AshrafShemirani,
    text: "As an Executive Assistant at the firm, Ashraf supports the partners and Seven Seven Six’s community of founders and our internal team. Before joining the team, Ashraf held administrative roles at WeWork, Sequoia Capital, and Sidewalk Infrastructure Partners.",
    socials: {
      linkedin: "https://www.linkedin.com/in/ashrafshemirani",
    },
  },
];

export default data;
