import { BlogPost } from "blog";
import { FC } from "react";
import moment from "moment";
import styled from "styled-components";

const CardWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  position: relative;
  transition: all 0.3s ease-in-out;

  .news-image {
    position: absolute;
    right: 70px;
    bottom: 15px;
    width: 436px;
    height: 309px;
    object-fit: cover;
    object-position: center;
    transform: rotate(-20deg);
    opacity: 0;
    transition: all 0.4s ease;
    pointer-events: none;
  }

  .content {
    width: calc(100% - 530px);
    .title {
      margin: 0px;
      color: ${({ theme }) => theme.colors.layoutDark};
      font-size: 31px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .written-by {
      margin: 0px;
      color: ${({ theme }) => theme.colors.textDisabled};
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
    }
  }

  &:hover {
    box-shadow: 0px 4px #000;
    border-bottom-color: ${({ theme }) => theme.colors.layoutDark};
    .title {
      color: ${({ theme }) => theme.colors.textSecondary};
      cursor: pointer;
    }
    .news-image {
      transform: rotate(0deg);
      opacity: 1;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowDesktop} {
    .news-image {
      width: 349px;
      height: 247px;
      right: 30px;
    }
    .content {
      width: calc(100% - 380px);
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    .news-image {
      display: none;
    }
    .content {
      width: 100%;
      .title {
        font-size: 26px;
        text-overflow: unset;
        white-space: normal;
      }

      .written-by {
        font-size: 16px;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    .content {
      width: 100%;
      .title {
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 29px;
      }

      .written-by {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`;

interface NewsCardProps {
  post: BlogPost;
  index?: number;
}

const NewsCard: FC<NewsCardProps> = ({ post }) => {
  return (
    <CardWrapper>
      <div className="content">
        <p className="title">{post.title}</p>
        <p className="written-by">
          By {post.author} • {moment(post.date).format("LL")}
        </p>
      </div>
      <img src={post.image} alt={post.title} className="news-image" />
    </CardWrapper>
  );
};

export default NewsCard;
