import { ThemeProvider } from "styled-components/macro";
import colors from "./colors";

interface ThemeProps {
  children: JSX.Element;
}

export interface ITheme {
  colors: {
    layoutDark: string;
    layoutLight: string;
    layoutNeutral: string;
    layoutMedium: string;
    textPrimary: string;
    textSecondary: string;
    textDisabled: string;
    brandColor: string;
    errorColor: string;
  };
  mediaQueries: {
    phone: string;
    tabletPortrait: string;
    tabletLandscape: string;
    desktop: string;

    bellowPhone: string;
    bellowTabletPortrait: string;
    bellowTabletLandscape: string;
    bellowDesktop: string;
    mediumDesktop: string;
  };
}

const theme: ITheme = {
  colors,
  mediaQueries: {
    phone: "only screen and (min-width: 576px)",
    tabletPortrait: "only screen and (min-width: 768px)",
    tabletLandscape: "only screen and (min-width: 1024px)",
    desktop: "only screen and (min-width: 1280px)",
    bellowPhone: "only screen and (max-width: 575.98px)",
    bellowTabletPortrait: "only screen and (max-width: 767.98px)",
    bellowTabletLandscape: "only screen and (max-width: 1023.98px)",
    bellowDesktop: "only screen and (max-width: 1279.98px)",
    mediumDesktop: "only screen and (max-width: 1500px)",
  },
};

const Theme = ({ children }: ThemeProps): JSX.Element => {
  return <ThemeProvider theme={{ ...theme }}>{children}</ThemeProvider>;
};

export default Theme;
