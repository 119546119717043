import { action, makeAutoObservable, observable } from "mobx";

export default class UserStore {
  @observable
  public email: string = null;

  @observable
  public isLoggedIn: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public toggleIsLoggedIn = () => {
    this.isLoggedIn = !this.isLoggedIn;
  };

  @action
  public setEmail = (email: string) => {
    this.email = email;
  };
}
