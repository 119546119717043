import Image from "assets/images/blog/Low-poly-father-and-son_without-background-980x695.png";
import { Link } from "react-router-dom";

const theSevenSevenSix2GrowthCaregivingCommitment = {
  id: "the-seven-seven-six-2-growth-caregiving-commitment",
  author: "Katelin Holloway",
  date: new Date("04/20/2021"),
  title: "The Seven Seven Six 2% Growth & Caregiving Commitment",
  image: Image,
  text: (
    <>
      <h3>
        <p className="bold-text">
          Over the course of my career, I have had the privilege of providing counsel to business leaders on all
          varieties of plans and decisions. Each relationship was unique and spanned a different period in a company's
          lifecycle, covering both times of exciting potential and intensifying challenge. With a background in People
          &amp; Culture, I gave advice to leaders that was different from what they were used to hearing in times of
          uncertainty: “This is hard, and you can do this—but you aren't much help if you have nothing left to give.”
        </p>
      </h3>
      <p>
        <strong>
          Founders often adopt the “leaders eat last” mentality, but if 2020 has taught us anything, it's that
          resilience and a forward-thinking mindset can beat incredible odds.&nbsp;
        </strong>
      </p>
      <p>
        No matter what the challenge is, leaders are better equipped to handle it if they invest in personal care and
        growth. Still, we all too often see founders prioritize their venture dollars to maximize business results.
        Rarely do we see founders directing dollars to their own wellbeing. Oftentimes, they're neglecting themselves
        and their family needs in service of the business. And the way we see it, that mentality puts founders at risk
        of experiencing a host of issues: burning out, having marital issues, experiencing loneliness, feeling
        disconnected from friends and loved ones, not processing grief, deprioritizing physical health, and more—all of
        which present real risks to a company's success.&nbsp;In short, you can't make a company thrive if you are
        barely keeping it together yourself.
      </p>
      <p>
        We believe in investing in leaders who put their people first, and this needs to start from the founders
        themselves. It is our responsibility to cultivate that mindset. The Seven Seven Six Growth &amp; Caregiving
        Program will therefore commit 1% on top of every check the firm writes* to non-dilutive capital earmarked for
        “founder personal growth” and an additional 1% to “caregiving.” These dollars will sit in a separate pool of
        funds for exclusive use by our founders for several years after the initial investment.
      </p>
      <p>
        Since every founder is unique, we have loose guidelines for how this money can be spent. For example, the
        personal growth funds can be applied toward anything from enrolling in therapy or coaching sessions, buying a
        new surfboard, or out-of-pocket medical costs. Flexibility and inclusion are at the heart of the program, and we
        encourage our leaders to make the call on where these resources can have the greatest value.
      </p>
      <p>
        We're operating under the assumption that our founders are high-integrity adults who know what they need to grow
        and develop, both personally and professionally, to achieve maximum mental and physical health. Plus, in our
        view, people who are lifelong learners and devoted to achieving balance and integration in their lives tend to
        be more agile, allowing them to adjust quickly to changing environments. These characteristics in a leader can
        translate into life or death for the business.&nbsp;
      </p>
      <p>
        Likewise, the caregiving fund can be applied toward anything that allows our founders to best support the people
        they love, through both the abundance and devastations of life. Most existing policies and programs are limited
        in their support of only two major life events—the birth of a child and the death of a loved one. Taking it
        further, founders rarely apply company handbook policies to their own lives, fearing the business will derail
        without their watchful eye and constant participation.&nbsp;
      </p>
      <p>
        We believe that there is far more complexity to the human experience and, yes, that includes for founders.
        Accordingly, this part of the program was created with the intent to support leaders throughout the course of
        the beautiful and heartbreaking moments they may encounter on their journeys, from childcare to plane tickets,
        to visiting an ill loved one. Whatever the case, these dollars will go toward supporting founders as they seek
        to find balance and presence in wherever they are most needed.&nbsp;
      </p>
      <p>
        Drawing on our own lived experiences through bringing children into the world, losing people we love, and
        supporting our communities through dynamic personal challenges, we know how compelling a program like this will
        be.
      </p>
      <br />
      <p>
        <strong>
          We believe that it's our founders' job to care for their employees and our job to care for our founders.&nbsp;
        </strong>
      </p>
      <br />
      <p>
        Seven Seven Six's founding team members have worked together closely for the past six years, never straying from
        our commitment to supporting employees, founders, and our communities with flexible benefits and policy changes.
        We've fought for paid family leave, charted new territory with policies like miscarriage leave and support, and
        simply done the right thing when we're sitting across from someone sharing hard news. Now, it only makes sense
        that we're the team prioritizing this in our firm from the onset of the very first fund.&nbsp;
      </p>
      <p>
        We also believe in open-sourcing goodness. We'll track, measure, and share the program data to help maximize our
        impact and encourage others to join in the development. We drew inspiration from the{" "}
        <Link
          to="https://www.forbes.com/sites/alexkonrad/2018/09/10/vc-firm-pledges-1-percent-to-founder-health/?sh=2572845872ec"
          target="_blank"
          rel="noopener noreferrer"
        >
          Felicis 1% program
        </Link>{" "}
        and are hopeful more venture capital firms will make similar commitments following our example.
      </p>
      <p>
        We view this as the first of many opportunities to evolve our industry and build a more compassionate future—one
        that embraces the human experience to achieve great success.&nbsp;
      </p>
      <p>
        *<em>based on first check at pre-seed and seed stage investments</em>
      </p>
      <p></p>
    </>
  ),
};

export default theSevenSevenSix2GrowthCaregivingCommitment;
