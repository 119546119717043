import clsx from "clsx";
import { FC } from "react";
import styled from "styled-components";

const MainButton = styled.button`
  height: 55px;
  border: 2px solid ${({ theme }) => theme.colors.brandColor};
  color: ${({ theme }) => theme.colors.brandColor};
  border-radius: 999px;
  font-size: 21px;
  font-weight: 400;
  padding: 0 25px;
  transition: all ease .2s;
  background-color: transparent;
  &:hover, &:focus {
    background-color: ${({ theme }) => theme.colors.brandColor};
    color: ${({ theme }) => theme.colors.layoutNeutral};
  }

  &.medium {
    height: 45px;
    font-size: 21px;
    font-weight: 400;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    width: 100%;
  }
`;

interface ButtonProps {
  children: React.ReactNode;
  type?: string;
  className?: string;
  disabled?: boolean;
  medium?: boolean;
}

const OutlinedButton: FC<ButtonProps> = ({ children, type, className, disabled, medium }) => {
  return (
    <MainButton className={clsx(className, medium && "medium")} type={type} disabled={disabled}>
      {children}
    </MainButton>
  );
};

OutlinedButton.defaultProps = {
  type: "button",
};

export default OutlinedButton;
