import clsx from "clsx";
import { FC } from "react";
import styled from "styled-components";

const FadeUpDiv = styled.div`
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &.animate {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface FadeUpProps {
  className?: string;
  children?: React.ReactNode;
  show: boolean;
}

const FadeUp: FC<FadeUpProps> = ({ className, children, show }) => {
  return <FadeUpDiv className={clsx(className, show && "animate")}>{children}</FadeUpDiv>;
};

export default FadeUp;
