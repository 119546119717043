import { inject } from "mobx-react";

import UserStore from "./UserStore";

export default class RootStore {
  user: UserStore;

  constructor() {
    this.user = new UserStore();
  }
}

export interface WithStoreProps {
  store?: RootStore;
}

export const injectStore = inject(`store`);
