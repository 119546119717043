import { FC, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import LyricsSection from "components/home/LyricsSection";
import StatsSection from "components/home/StatsSection";
import { Helmet } from "react-helmet";
import { APP_TITLE } from "config/Config";
import clsx from "clsx";
import useScrollToTop from "components/common/useScrollToTop";
import { Link } from "react-router-dom";
import { RoutesEnum } from "utils/Routes";
import { stats365, stats776Founders, statsUsers } from "enum/stats";
import { Footer, Header } from "components";
import useScreenSize from "components/common/useScreenSize";
import useScrollTop from "components/common/useScrollTop";
import ScrollImages from "components/common/ScrollImages";
import useStats from "components/useStats";
import useTeamMembers from "components/useTeamMembers";

const BackgroundDiv = styled.div`
  width: 100%;
  max-width: 1740px;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  @media ${({ theme }) => theme.mediaQueries.bellowDesktop} {
    justify-content: flex-start;
    padding: 0 70px;
  }
  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    padding: 0 25px;
  }
`;

const HomeDiv = styled.div`
  position: relative;
  .main-width {
    width: 100%;
    max-width: 1740px;
    padding: 0 70px;
    margin: 0 auto;
  }

  .main-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 190px 0 0 0;
    font-size: 100px;
    font-weight: 400;
    line-height: 120px;
    text-transform: capitalize;
    margin: 0;
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  .receipts {
    max-width: 830px;
    font-size: 28px;
    font-weight: 400;
    line-height: 46px;
    margin: 70px 0 70px 235px;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.textPrimary};
    &.animate div {
      opacity: 1;
      transform: translateY(0);
    }

    svg {
      fill: ${({ theme }) => theme.colors.brandColor};
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowDesktop} {
    .main-text {
      padding: 150px 25px 0 25px;
      font-size: 69px;
      font-weight: 400;
      line-height: 80px;
    }

    .receipts {
      padding: 0 25px;
      margin-top: 50px;
      margin-bottom: 50px;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    .main-width {
      padding: 0;
    }

    .receipts {
      max-width: 585px;
    }

    .stats-container {
      padding: 0 25px;
    }

    .lyrics {
      padding: 0 25px;
    }

    .fixed {
      position: relative;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    .main-text {
      color: ${({ theme }) => theme.colors.textPrimary};
      font-size: 40px;
      font-weight: 400;
      line-height: 52px;
      padding: 150px 25px 0 25px;
    }

    .receipts {
      font-size: 19px;
      font-weight: 400;
      line-height: 31px;
      margin: 20px 0 45px 0;
    }
  }

  @media all and (orientation: landscape) and (max-height: 1024px) {
    .main-text {
      padding-top: 150px;
      font-size: 69px;
      font-weight: 400;
      line-height: 80px;
    }

    .receipts {
      padding: 0 25px;
      margin-top: 50px;
      margin-bottom: 50px;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
    }
  }

  @media all and (orientation: landscape) and (max-height: 768px) {
    .main-text {
      font-size: 40px;
      font-weight: 400;
      line-height: 52px;
    }

    .receipts {
      font-size: 19px;
      font-weight: 400;
      line-height: 31px;
      margin: 20px 0 45px 235px;
    }
  }
`;

const Home: FC = () => {
  useScrollToTop();
  const ref = useRef(null);
  const receiptsRef = useRef(null);
  const lyricsRef = useRef(null);
  const footerRef = useRef(null);

  const [animateLyrics, setAnimateLyrics] = useState<boolean>(false);

  const { items: teamMembers } = useTeamMembers();
  const { stats, loading, loadStats } = useStats();
  const scrollTop = useScrollTop();
  const { isMobile, height: screenHeight, width: screenWidth } = useScreenSize();

  const isMediumDesktop = screenWidth <= 1280;
  const isTabletOrMobile = screenHeight < 800 || screenWidth <= 1024;

  const lyricsAnimationStart = lyricsRef.current?.offsetTop + lyricsRef.current?.offsetHeight / 2 - screenHeight / 2;
  const lyricsLogoHeight = isMobile ? 110 : isMediumDesktop ? 100 : 0;
  const mainAnimationEnd = isMediumDesktop ? lyricsAnimationStart - lyricsLogoHeight : lyricsAnimationStart;

  useEffect(() => {
    if (!lyricsRef.current) return;
    if (scrollTop > lyricsAnimationStart && !animateLyrics) {
      setAnimateLyrics(true);
    }
  }, [animateLyrics, setAnimateLyrics, lyricsAnimationStart, lyricsRef, screenHeight, scrollTop]);

  const blendSupported = useMemo(() => CSS?.supports("mix-blend-mode", "multiply"), []);

  useEffect(() => {
    loadStats();
  }, [loadStats]);

  const allStats = useMemo(() => {
    const mainStats = stats365(stats);
    const founderStats = stats776Founders(stats, false);
    const userStats = statsUsers(stats, teamMembers.length);
    return [...mainStats, ...founderStats, ...userStats];
  }, [stats, teamMembers?.length]);

  const yearsOld = useMemo(() => {
    const startDate = new Date("2020-09-01").getTime();
    const yearsDifference = (Date.now() - startDate) / (365.25 * 24 * 60 * 60 * 1000);
    return Math.round(yearsDifference * 10) / 10;
  }, []);

  if (loading) return null;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} — A technology company that deploys venture capital</title>
      </Helmet>

      <Header lyricsSectionHeight={mainAnimationEnd} />

      <BackgroundDiv className={blendSupported && "supported"}>
        <ScrollImages height={mainAnimationEnd} />
      </BackgroundDiv>

      <HomeDiv ref={ref}>
        <div className="fixed">
          <section className="main-width">
            <h2 className={clsx("main-text")}>
              A Tech Company That
              {!isMobile && <br />} Deploys Venture Capital
            </h2>
          </section>

          <div className="main-width">
            <div ref={receiptsRef} className="receipts">
              Founded by builders, built by founders in order to be the very {screenWidth >= 1024 && <br />}
              best at supporting founders changing the world.
              <br />
              <br />
              And we bring receipts. <Link to={RoutesEnum.alexis}>🧾</Link>
              <br />
              We're a {yearsOld}-year-old early stage VC firm.
              <br />
              As a team of {teamMembers.length}, here's what we did in the last 365 days:
            </div>

            <div className="stats-container">
              <StatsSection stats={allStats} squaredBottom={!isTabletOrMobile} />
            </div>
          </div>

          <div ref={lyricsRef} className="main-width lyrics">
            <LyricsSection animate={animateLyrics} showLogo={scrollTop >= mainAnimationEnd} />
          </div>

          <div ref={footerRef}>
            <Footer />
          </div>
        </div>
      </HomeDiv>
    </>
  );
};

export default Home;
