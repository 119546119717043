import { API_URL } from "config/Config";
import { useCallback, useEffect, useState } from "react";
import teamMembersData, { TeamCard } from "team";
import { processServerResponse } from "utils/httpUtils";

export interface Muse {
  linkToAsset: string;
  linkToPage: string;
  linkToAssetCoverImage: string;
  linkToDownload: string;
  recorderName: string;
  watermark: boolean;
}

const useTeamMembers = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<TeamCard[]>([]);

  const loadItems = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/public/landing-page/sevensevensix/members`, {
        method: "GET",
      });
      const data = await processServerResponse(response);
      const sorted = (data || []).sort((a, b) => a.displayPosition - b.displayPosition);
      const mapped = sorted.map((item, index): TeamCard => {
        const socials = {
          twitter: item.twitter,
          instagram: item.instagram,
          linkedin: item.linkedin,
          tiktok: item.tiktok,
          angellist: item.angelList,
          web: item.web,
        };
        return {
          id: `${index + 1}`,
          name: [item.firstName, item.lastName].filter((v) => !!v).join(" "),
          position: item.position,
          image: item.picture,
          text: item.bio,
          socials: Object.fromEntries(Object.entries(socials).filter(([_, v]) => !!v)),
        };
      });
      setItems(mapped);
    } catch (e) {
      setItems(teamMembersData);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return {
    items,
    loading,
  };
};

export default useTeamMembers;
