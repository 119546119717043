import { ColSpanEnum } from "enum/stats";

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const formatNumber = (number) => new Intl.NumberFormat().format(Math.trunc(number));

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const getBaseUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    let baseUrl = parsedUrl.hostname;

    if (!baseUrl.startsWith("www.")) {
      baseUrl = "www." + baseUrl;
    }

    return baseUrl;
  } catch (error) {
    return url;
  }
};

export const generateColSpanClasses = (size: ColSpanEnum, classPrefix = "&") => {
  let res = "";

  for (let index = 0; index < 60; index++) {
    res += ` ${classPrefix}.${size}-${index + 1}{
        grid-column: span ${index + 1}; 
      }`;
  }

  return res;
};

export const getWauMauStats = (wau: number, mau: number) => {
  return Math.round((wau / mau) * 100);
};

export const calculatePercentage = (total: number, chunk: number, prefix: string = " (", suffix: string = "%)") => {
  if (!total || total < 0) {
    return "";
  }
  if (!chunk || chunk < 0) {
    return "";
  }

  const percentage = (chunk / total) * 100;

  return `${prefix}${Math.round(percentage)}${suffix}`;
};
