import Image from "assets/images/blog/Low-Poly-Runners-980x398.png";
import { Link } from "react-router-dom";

const talentOutcomes = {
  id: "talent-outcomes",
  author: "Chris Vanzetta",
  date: new Date("09/20/2022"),
  title: "Talent Outcomes at Seven Seven Six",
  image: Image,
  imageSource: "Original art by Guss Navarro",
  text: (
    <>
      <h3>
        <p className="bold-text">
          At Seven Seven Six, we're committed to innovating everything from products to processes. We share our
          learnings—and what we'd do differently—in the hopes of pushing tech and VC forward as industries. So, with
          that in mind, here's what we're doing with our Talent function.
        </p>
      </h3>
      <p>
        To start, you may have noticed we're calling this function Talent <em>Outcomes</em>. We've chosen the word
        “outcomes” to reflect the impact of our work and the accountability we'll share in the results of these efforts.
        This function will sit directly next to - and work in tandem with - the{" "}
        <Link to="/news/the-nuance-and-the-need-for-founder-outcomes" target="_blank" rel="noreferrer">
          Founder Outcomes Team
        </Link>
        , led by my colleague{" "}
        <Link
          to="https://www.linkedin.com/search/results/all/?keywords=cristina%20apple%20georgoulakis&amp;origin=RICH_QUERY_SUGGESTION&amp;position=0&amp;searchId=ad7956c0-99ca-48d3-8f42-db6b7bc58e8d"
          target="_blank"
          rel="noreferrer"
        >
          Cristina Georgoulakis
        </Link>
        . Together, these two areas of focus will ensure our portfolio companies are values and goal aligned, producing
        the greatest possible returns and building the future of tech.
      </p>
      <p>
        In a traditional venture firm, the Talent team is responsible for making introductions to (or even placing
        candidates at) their portfolio companies. It is hyper-focused on talent attraction and helping their portfolio
        companies recruit. While this may feel like it solves an immediate pain point, this only represents one very
        small part of a founder's real challenge. Getting them in the door is one thing. Creating an environment where
        they want to stay and a company they want to grow with is another.&nbsp;
      </p>
      <p>
        Whether the candidate happens to work out or not—it can go either way—filling that role is just one part of
        cultivating talent. Issues such as building compensation strategy, a performance management program grounded in
        clear expectations and consistent feedback, building a diversity and inclusion roadmap to drive employee
        engagement, or working through organizational design and culture challenges are far more impactful in making
        sure an employee stays. One qualified lead for a key role, enticing as that may seem in the moment, will not
        enable a company to effectively recruit or retain talent into the future.&nbsp;
      </p>
      <p>
        We believe that teaching our founders and their leaders <em>how</em> to recruit top talent is far more valuable
        than helping to fill a handful of open roles. Directly staffing roles for our portfolio companies can't and
        won't scale. Access to our network of over tens of thousands of people through Cerebro (our firm's proprietary
        software) will. A direct introduction tool eases top of funnel candidate congestion. The depth of experience in
        our Talent Outcomes function will give the founders the tools and strategies they need to build a recruiting and
        retention strategy that scales. This combination of software, coaching, training, and closing will ensure our
        founders get their key roles filled quickly and effectively.
      </p>
      <p>
        In addition to coaching and network access, we feel strongly that a company's biggest competitive advantage is{" "}
        <strong>culture</strong>. Developing, retaining, and rewarding talent is all a part of our Talent Outcomes
        strategy and programming. We have no business (or interest) in trying to be executive recruiters to hundreds of
        portfolio companies. Instead, we're placing our resources into learning, development, and software to help our
        founders build sustainable, scalable, and holistic people &amp; culture practices.
      </p>
      <p></p>
      <h2>The Talent Outcomes Playbook</h2>
      <p>Our founders will engage with the Talent Outcomes team in a variety of ways:</p>
      <ul>
        <li>
          <strong>1:1 Coaching:</strong> This is for those “oh s*#!, red alert” moments where our founders need
          immediate support, guidance and coaching. It might not always be that urgent, but when the founder needs
          strategic advice from a people &amp; culture perspective, we will be there to guide them.
        </li>
        <li>
          <strong>1:1 Workshops:</strong> This is for when our founders need to solve a more complicated problem that
          will take us a lot of time, headspace, and energy. From building a competitive compensation &amp; job
          architecture strategy, to revamping recruiting and interview processes, to building a diversity and inclusion
          roadmap, our Talent Outcomes team is equipped with the tools and experience to go deep with our founders and
          early teams to help solve their most pressing people &amp; culture problems with precision.&nbsp;
        </li>
        <li>
          <strong>1 to Many: </strong>To help us scale our impact, we will build and share resources and best practices
          to give our founders the on-demand support they need to build talent-dense teams and world class cultures. We
          will partner with industry experts to bring to live trainings, workshops and the like to help upskill our
          founders.
        </li>
        <li>
          <strong>Technology</strong>: Everything above requires a human with EQ and lived experience. But sometimes,
          technology can be even more efficient and effective than humans. As we continue to build Cerebro, our firm's
          internal operating system, we will develop a world class talent network that will help our founders make
          connections with other founders, investors, and the best talent on the market for their teams.&nbsp;
        </li>
      </ul>
      <p></p>
      <h2>What's Next?</h2>
      <p>
        The reality is, we're still a very small team and there is no way that I can do this by myself. We already have
        well over 50 portfolio companies, 100+ founders, and just one me. The Talent Outcomes team will need to grow as
        we continue to raise more capital, onboard new portfolio companies and founders, and continue to iterate on how
        we can scale our support with technology.&nbsp;
      </p>
      <p>
        As we look into the future, the most critical need we will hire for is someone with deep Talent Acquisition
        experience. A leader in this space who has done the heavy lifting, helping organizations scale from 0 → 1 →
        many, that has built and scaled recruiting operations, and has an innate ability to coach senior leaders on how
        to build diverse, talent dense teams. If that sounds like you, keep your ears and eyes peeled for an open job
        application soon!
      </p>
      <p>
        As we like to say here at the firm: we're just at the starting line. I am grateful to be on this team, doing the
        work I know and love so very much. I can't wait to share our progress and learnings with you all as we continue
        to build this function!
      </p>
    </>
  ),
};

export default talentOutcomes;
