import { useState, useRef, useEffect } from "react";

const useCounter = (limit, duration = 250) => {
  const animationIndexRef = useRef(0);
  const [animationIndex, setAnimationIndex] = useState(0);

  useEffect(() => {
    if (!limit) return;

    setAnimationIndex(0);
    const interval = setInterval(() => {
      const number = animationIndexRef.current + 1;
      animationIndexRef.current = number;
      setAnimationIndex(number);
      if (number > limit) {
        clearInterval(interval);
      }
    }, duration);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  return animationIndex;
};

export default useCounter;