import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";

import { Helmet } from "react-helmet";
import styled from "styled-components";
import moment from "moment";
import { rgba } from "polished";

import { APP_TITLE } from "config/Config";
import { RoutesEnum } from "utils/Routes";
import NewsItemNavigation from "components/news/NewsItemNavigation";
import blogPosts, { BlogPost } from "blog";

import { ReactComponent as XIcon } from "../assets/icons/x.svg";
import { ReactComponent as LinkedinIcon } from "../assets/icons/linkedin.svg";
import useScrollToTop from "components/common/useScrollToTop";
import { Header } from "components";
import Shape from "components/common/Shape";

const NewsItemPageSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.layoutNeutral};

  .content-wrapper {
    position: relative;
    z-index: 1;
    padding: 175px 0 0 0;
  }

  .news-item-content {
    max-width: 1200px;
    width: 100%;
    padding-bottom: 215px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;

    .news-title {
      color: ${({ theme }) => theme.colors.layoutDark};
      font-size: 55px;
      font-weight: 400;
      line-height: 1.4;
      margin-bottom: 20px;
    }

    .written-by {
      color: ${({ theme }) => theme.colors.textDisabled};
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 30px;
    }

    .news-image {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
      object-fit: cover;
    }

    .image-source {
      color: ${({ theme }) => theme.colors.textDisabled};
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      margin: 0px;
    }

    .news-content {
      margin-top: 30px;
      color: ${({ theme }) => theme.colors.textPrimary};
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px;

      .bold-text {
        font-weight: 500;
      }

      h2 {
        font-size: 28px;
        font-weight: 400;
        line-height: 46px;
      }

      h3 p {
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 46px;
        margin-bottom: 40px;
      }

      a {
        color: ${({ theme }) => theme.colors.brandColor};
        text-decoration: underline;
      }

      ul,
      ol {
        padding-left: 1.5rem;
      }

      img {
        border-radius: 10px;
        width: 100%;
      }
    }

    .news-content *::selection {
      background-color: ${({ theme }) => rgba(theme.colors.brandColor, 0.2)};
    }

    .h-padding {
      padding: 0 25px;
    }

    .h-width {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
    }

    @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
      padding-bottom: 50px;

      .written-by {
        font-size: calc(13rem / 16);
      }

      .news-image {
        border-radius: 0px;
      }

      .news-content .bold-text {
        font-size: calc(17rem / 16);
        line-height: calc(27rem / 16);
      }
    }

    @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
      .news-image {
        height: 200px;
      }
    }
  }

  .social-media-container {
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 0;
    align-self: flex-start;
    transform: translate(-150px, 0);
    z-index: 10;

    color: ${({ theme }) => theme.colors.textPrimary};

    .share-post {
      color: ${({ theme }) => theme.colors.textPrimary};
      font-size: calc(14rem / 16);
      font-weight: 500;
      line-height: 25px;
    }

    p {
      margin: 0;
    }

    .icons {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  @media all and (max-width: 1550px) {
    .news-item-content {
      margin: 0 auto;
      padding-left: 120px;
      padding-right: 20px;
      box-sizing: content-box;
    }

    .social-media-container {
      transform: translate(-100px, 0);
    }
  }

  @media all and (max-width: 1300px) {
    .news-item-content {
      padding: 0 0 215px 0;
    }

    .social-media-container {
      position: fixed;
      bottom: 0px !important;
      left: 0;
      top: initial !important;
      width: 100%;
      height: fit-content;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background: ${({ theme }) => theme.colors.layoutDark};
      padding: 10px 20px 10px 25px;
      color: white;
      transform: translate(0px);
      top: auto;
      z-index: 10;

      .share-post {
        color: ${({ theme }) => theme.colors.layoutLight};
      }

      .icons {
        flex-direction: row;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowDesktop} {
    .title-section {
      padding: 0 25px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    .content-wrapper {
      padding-top: 115px;
    }

    .news-item-content {
      .news-title {
        font-size: 40px;
      }

      .written-by {
        font-size: 16px;
      }

      .news-content {
        font-size: 17px;
        line-height: 1.888;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    .news-item-content {
      .news-title {
        font-size: 32px;
        font-weight: 400;
        line-height: 42px;
        margin-bottom: 20px;
      }

      .written-by {
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }

      .news-content {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
`;

const StyledXIcon = styled(XIcon)`
  path {
    fill: ${({ theme }) => theme.colors.layoutDark};
  }
  circle {
    stroke: ${({ theme }) => theme.colors.layoutDark};
  }

  &:hover {
    path {
      transition: fill 0.3s ease-in-out;
      fill: ${({ theme }) => theme.colors.layoutLight};
    }
    transition: fill 0.3s ease-in-out;
    fill: ${({ theme }) => theme.colors.layoutDark};
  }

  @media all and (max-width: 1300px) {
    width: 47px;
    height: 46px;

    path {
      stroke: none;
      fill: ${({ theme }) => theme.colors.layoutLight};
    }

    circle {
      stroke: ${({ theme }) => theme.colors.layoutLight};
    }
  }
`;
const StyledLinkedinIcon = styled(LinkedinIcon)`
  path {
    fill: ${({ theme }) => theme.colors.layoutDark};
  }
  circle {
    stroke: ${({ theme }) => theme.colors.layoutDark};
  }

  &:hover {
    path {
      transition: fill 0.3s ease-in-out;
      fill: ${({ theme }) => theme.colors.layoutLight};
    }
    transition: fill 0.3s ease-in-out;
    fill: ${({ theme }) => theme.colors.layoutDark};
  }

  @media all and (max-width: 1300px) {
    width: 47px;
    height: 46px;

    path {
      stroke: none;
      fill: ${({ theme }) => theme.colors.layoutLight};
    }

    circle {
      stroke: ${({ theme }) => theme.colors.layoutLight};
    }
  }
`;

const NewsItem: FC = () => {
  useScrollToTop();
  const history = useHistory();
  const params = useParams();

  const imgRef = useRef<HTMLImageElement>(null);
  const [imageFromTop, setImageFromTop] = useState<number>(0);

  const id = useMemo(() => {
    return params.id;
  }, [params]);

  const post: BlogPost = useMemo(() => {
    return blogPosts.find((post) => post.id === id);
  }, [id]);

  useEffect(() => {
    setImageFromTop(imgRef.current.offsetTop);
  }, [imgRef]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (id && !blogPosts.some((post) => post.id === id)) {
      history.push(RoutesEnum.news);
    }
  }, [id, history]);

  if (!post) return null;

  return (
    <NewsItemPageSection>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {APP_TITLE} — {post.title}
        </title>
      </Helmet>

      <Shape />

      <div className="content-wrapper">
        <Header showShape />

        <div className="news-item-content">
          <div
            className="social-media-container"
            style={{
              top: imageFromTop + "px",
            }}
          >
            <p className="share-post">Share Post</p>
            <div className="icons">
              <a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target="_blank" rel="noreferrer">
                <StyledXIcon width="56" height="55" />
              </a>
              <a
                href={`https://www.linkedin.com/shareArticle/?mini=true&url=${window.location.href}`}
                target="_blank"
                rel="noreferrer"
              >
                <StyledLinkedinIcon width="56" height="55" />
              </a>
            </div>
          </div>
          <div className="title-section">
            <p className="news-title">{post.title}</p>

            <p className="written-by">
              By {post.author} • {moment(post.date).format("LL")}
            </p>
          </div>

          <img src={post.image} alt={post.title} className="news-image" ref={imgRef} />

          <div className="h-padding h-width">
            {!!post.imageSource && <p className="image-source">{post.imageSource}</p>}

            <div className="news-content">{post.text}</div>
          </div>
        </div>
      </div>

      <NewsItemNavigation id={post.id} />
    </NewsItemPageSection>
  );
};

export default NewsItem;
