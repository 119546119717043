import { useEffect } from "react";

const useScrollToTop = () => {
  useEffect(() => {
    const root = document.querySelector(":root");
    root.setAttribute("style", "scroll-behavior: auto;");
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
      root.removeAttribute("style");
    }, 0);
  }, []);
};

export default useScrollToTop;
