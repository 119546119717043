import { API_URL } from "config/Config";
import { StatsInterface } from "enum/stats";
import { useCallback, useState } from "react";
import { processServerResponse } from "utils/httpUtils";

const useStats = () => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<StatsInterface>(null);

  const loadStats = useCallback(async (isAlexis?: boolean) => {
    setLoading(true);
    try {
      const payload = new URLSearchParams();
      if (isAlexis) payload.append("alexisOnly", "true");

      const response = await fetch(`${API_URL}/sevensevensix/stats?${payload.toString()}`, {
        method: "GET",
      });
      const data = (await processServerResponse(response)) as StatsInterface;

      setStats(data || {});
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    stats,
    loading,
    loadStats,
  };
};

export default useStats;
